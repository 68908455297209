import React from "react";
import ReactDOM from "react-dom";
// import "antd/dist/antd.css";
import 'antd/dist/antd.min.css';
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";
import { BrowserRouter as Router } from "react-router-dom";
//import "./local"

const { language } = store.getState();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: language.language,
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ar: {
      common: common_ar,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
